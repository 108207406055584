<template>
  <v-app>
    <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
    <v-sheet class="py-12 px-4" color="#F8ECDC" height="100%" min-width="410px">
      <v-card outlined color="rgba(0,0,0,0)">
        <div class="mt-12 mb-6" style="font-size: 30px">
          <span>定期便一覧</span>
        </div>
        <v-row>
          <v-spacer></v-spacer>
          <v-col v-for="(subscription, index) in displayList1" :key="index" cols="12" sm="6" md="4" lg="4">
            <v-card outlined color="rgb(0,0,0,0)" style="max-width: 500px; margin: auto">
              <div
                class="d-flex justify-center pointer"
                style="width: 100%; vertical-align: middle; display: table-cell"
              >
                <img
                  :src="subscription.image"
                  style="max-height: 100%; max-width: 100%; display: block; margin: auto"
                  :onerror="errorImage"
                  @click="jump(subscription)"
                />
                <v-img
                  v-if="showComingSoon(subscription)"
                  style="position: absolute; top: 0; left: 0"
                  src="@/assets/images/logos/BimiStock-ComingsoonMark.png"
                  contain
                  @click="jump(subscription)"
                >
                </v-img>
              </div>
              <v-row class="mt-3 mb-2" no-gutters>
                <v-col cols="7" sm="7" md="6" lg="7">
                  <div class="d-flex align-center" style="font-size: 18px; font-weight: bold; text-align: left">
                    <span>{{ subscription.subs_name }}</span>
                  </div>
                </v-col>
                <v-col cols="5" sm="5" md="6" lg="5" class="d-flex align-center justify-end">
                  <div>
                    <span style="color: #ff6e40; font-size: 23px; font-weight: bold">
                      ￥{{
                        subscription.subs_tip
                          ? subscription.subs_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                          : ''
                      }}
                      <!-- <del v-if="subscription.subs_up_value !== null" class="mr-6" style="font-size: 18px"
                    >￥{{
                      subscription.subs_up_value
                        ? subscription.subs_up_value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                        : ''
                    }}</del
                  > -->
                    </span>
                    <span style="color: #ff6e40; font-size: 14px; font-weight: bold">（税込）</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div
                    style="
                      font-family: 'Noto Sans JP', sans-serif;
                      font-weight: bold;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>販売期間: </span>
                    <span>{{ subscription.subs_ssd }}〜{{ subscription.subs_sed }}</span>
                  </div>
                </v-col>
              </v-row>
              <div
                class="desc"
                :style="{ height: computedHeight, 'font-family': `'Noto Sans JP', sans-serif`, 'font-weight': 'bold' }"
                v-html="subscription.subs_overview"
              ></div>
              <!-- <div v-if="!showComingSoon(subscription)"> -->
              <div>
                <!-- <v-card-actions>
                  <v-btn depressed color="#ff6e40" min-width="30%" min-height="42px" @click="decre(subscription)">
                    <v-icon color="#fff"> mdi-minus </v-icon>
                  </v-btn>
                  <div class="text-center py-2" style="border: 1px solid #2e2e22; border-radius: 5px; width: 40%">
                    <span>{{ subscription.number }}</span>
                  </div>
                  <v-btn depressed color="#ff6e40" min-width="30%" min-height="42px" @click="incre(subscription)">
                    <v-icon color="#fff"> mdi-plus </v-icon>
                  </v-btn>
                </v-card-actions> -->
                <div class="mx-2 d-flex justify-center">
                  <v-btn
                    class="mt-3"
                    style="margin: auto"
                    width="150px"
                    color="#ff6e40"
                    depressed
                    @click="jump(subscription)"
                  >
                    <span style="color: #fff">詳細を見る</span>
                  </v-btn>
                </div>
              </div>
              <!-- <div v-else>
                <v-img
                  src="@/assets/images/logos/comingSoon.jpg"
                  contain
                  width="100%"
                >
                </v-img>
              </div> -->
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col v-for="(subscription, index) in displayList2" :key="index" cols="12" sm="6" md="4" lg="4">
            <v-card outlined color="rgb(0,0,0,0)" style="max-width: 500px; margin: auto">
              <div
                class="d-flex justify-center pointer"
                style="width: 100%; vertical-align: middle; display: table-cell"
              >
                <img
                  :src="subscription.image"
                  style="max-height: 100%; max-width: 100%; display: block; margin: auto"
                  :onerror="errorImage"
                  @click="jump(subscription)"
                />
                <v-img
                  v-if="showComingSoon(subscription)"
                  style="position: absolute; top: 0; left: 0"
                  src="@/assets/images/logos/BimiStock-ComingsoonMark.png"
                  contain
                  @click="jump(subscription)"
                >
                </v-img>
              </div>
              <v-row class="mt-3 mb-2" no-gutters>
                <v-col cols="7" sm="7" md="6" lg="7">
                  <div class="d-flex align-center" style="font-size: 18px; font-weight: bold; text-align: left">
                    <span>{{ subscription.subs_name }}</span>
                  </div>
                </v-col>
                <v-col cols="5" sm="5" md="6" lg="5" class="d-flex align-center justify-end">
                  <div>
                    <span style="color: #ff6e40; font-size: 23px; font-weight: bold">
                      ￥{{
                        subscription.subs_tip
                          ? subscription.subs_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                          : ''
                      }}
                      <!-- <del v-if="subscription.subs_up_value !== null" class="mr-6" style="font-size: 18px"
                    >￥{{
                      subscription.subs_up_value
                        ? subscription.subs_up_value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                        : ''
                    }}</del
                  > -->
                    </span>
                    <span style="color: #ff6e40; font-size: 14px; font-weight: bold">（税込）</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div
                    style="
                      font-family: 'Noto Sans JP', sans-serif;
                      font-weight: bold;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>販売期間: </span>
                    <span>{{ subscription.subs_ssd }}〜{{ subscription.subs_sed }}</span>
                  </div>
                </v-col>
              </v-row>
              <div
                class="desc"
                :style="{ height: computedHeight, 'font-family': `'Noto Sans JP', sans-serif`, 'font-weight': 'bold' }"
                v-html="subscription.subs_overview"
              ></div>
              <!-- <div v-if="!showComingSoon(subscription)"> -->
              <div>
                <!-- <v-card-actions>
                  <v-btn depressed color="#ff6e40" min-width="30%" min-height="42px" @click="decre(subscription)">
                    <v-icon color="#fff"> mdi-minus </v-icon>
                  </v-btn>
                  <div class="text-center py-2" style="border: 1px solid #2e2e22; border-radius: 5px; width: 40%">
                    <span>{{ subscription.number }}</span>
                  </div>
                  <v-btn depressed color="#ff6e40" min-width="30%" min-height="42px" @click="incre(subscription)">
                    <v-icon color="#fff"> mdi-plus </v-icon>
                  </v-btn>
                </v-card-actions> -->
                <div class="mx-2 d-flex justify-center">
                  <v-btn
                    class="mt-3"
                    style="margin: auto"
                    width="150px"
                    color="#ff6e40"
                    depressed
                    @click="jump(subscription)"
                  >
                    <span style="color: #fff">詳細を見る</span>
                  </v-btn>
                </div>
              </div>
              <!-- <div v-else>
                <v-img
                  src="@/assets/images/logos/comingSoon.jpg"
                  contain
                  width="100%"
                >
                </v-img>
              </div> -->
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-pagination
          v-if="displayLists.length > 0"
          v-model="page"
          class="mt-12"
          :length="length"
          color="#ff6e40"
          @input="pageChange"
        ></v-pagination>
      </v-card>
    </v-sheet>
  </v-app>
</template>

<script>
import { mdiCart } from '@mdi/js'
import { mapMutations, mapActions, mapState } from 'vuex'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  data: () => ({
    icons: {
      mdiCart,
    },
    currentDate: '',
    errorImage: `this.src="${require('@/assets/images/logos/NoImage.png')}"`,
    List: [],
    page: 1,
    length: 0,
    displayLists: [],
    displayList1: [],
    displayList2: [],
    pageSize: 6,
    computedHeight: '',
    Form: {
      id: '',
      load: '',
      name: '',
      price_normal: '',
      count: 0,
    },
  }),
  computed: {
    ...mapState('cartStore', ['count', 'cartList']),
    ...mapState('subscriptionStore', ['subscriptionList']),
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('auth', ['isLoggedIn']),
    showComingSoon() {
      return function (subscription) {
        console.log('subscription', subscription)

        return Date.now() < Date.parse(subscription.subs_ssd)
      }
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    window.addEventListener('load', this.getWidth())
    window.addEventListener('resize', this.getWidth)
  },
  methods: {
    ...mapMutations('cartStore', ['increNavCount', 'addProduct']),
    ...mapActions('subscriptionStore', ['loadSubscriptionList']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),

    getWidth() {
      // if (window.innerWidth < 1200 && window.innerWidth > 600) {
      //   this.computedHeight = `${200}px`
      // } else {
      //   this.computedHeight = `${150}px`
      // }
    },
    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadSubscriptionList(this.$route.params)
        .then(() => {
          this.List = [...this.subscriptionList]
          console.log('List', this.List)
          this.List.forEach((sl, i) => {
            if (sl.subs_images !== null) {
              this.List[i].image = `${sl.imgUrl_prefix}/${sl.subs_images
                .replaceAll('"', '')
                .split(',')[0]
                .replace(/^\//g, '')}`
            } else {
              this.List[i].image = require('@/assets/images/logos/NoImage.png')
            }
            this.List[i].number = 1
          })
          this.displayLists = this.List.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
          if (this.displayLists.length % 4 === 0) {
            this.displayList1 = this.displayLists.slice(0, 2)
            this.displayList2 = this.displayLists.slice(3)
          } else {
            this.displayList1 = this.displayLists.slice(0, 3)
            this.displayList2 = this.displayLists.slice(3)
          }
          this.length = Math.ceil(this.List.length / this.pageSize)
        })
        .finally(() => {
          this.setAbsoluteOverlayStatus(false)
        })
    },

    /* eslint-disable */
    addToCart(subscription, index) {
      if (this.isLoggedIn === false) {
        this.$router.push('/login')
      }
      if (this.isLoggedIn === true) {
        const Form = {}
        Form.product_code = subscription.subs_code
        // Form.id = subscription.subs_id
        Form.load = subscription.image
        Form.name = subscription.subs_name
        Form.price_normal = subscription.subs_tip
        Form.count = subscription.number
        this.addProduct(Form)
        this.increNavCount(subscription.number)
        subscription.number = 1
        this.$set(this.displayLists, index, subscription)
      }
    },
    /* eslint-disable */

    incre(subscription) {
      if (subscription.number < 20) {
        subscription.number += 1
        this.displayLists = this.List.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
        this.length = Math.ceil(this.List.length / this.pageSize)
      }
    },
    decre(subscription) {
      if (subscription.number > 1) {
        subscription.number -= 1
      }
      this.displayLists = this.List.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
      this.length = Math.ceil(this.List.length / this.pageSize)
    },
    pageChange(pageNumber) {
      this.displayLists = this.List.slice(this.pageSize * (pageNumber - 1), this.pageSize * pageNumber)
      if (this.displayLists.length % 4 === 0) {
        this.displayList1 = this.displayLists.slice(0, 2)
        this.displayList2 = this.displayLists.slice(3)
      } else {
        this.displayList1 = this.displayLists.slice(0, 3)
        this.displayList2 = this.displayLists.slice(3)
      }
    },
    jump(item) {
      this.$router.push({
        name: 'subscription',
        params: {
          id: item.subs_id,
          // query: this.$route.query,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
del {
  color: grey;
}
.pointer :hover {
  cursor: pointer;
}
</style>
